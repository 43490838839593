import Vue from 'vue'
import Router from 'vue-router'

import Especifica from './views/Especifica.vue'
import Home from './views/Home.vue'

import ComponentConteudo404 from '@/components/ComponentConteudo404.vue'
import ComponentConteudoMapaDoSite from '@/components/ComponentConteudoMapaDoSite.vue'
import ComponentConteudoNossosProdutos from '@/components/ComponentConteudoNossosProdutos.vue'
import ComponentConteudoObstaculosVendaInternet from '@/components/ComponentConteudoObstaculosVendaInternet.vue'
import ComponentConteudoPorQueVenderPecasOnline from '@/components/ComponentConteudoPorQueVenderPecasOnline.vue'
import ComponentConteudoSobreOEPeca from '@/components/ComponentConteudoSobreOEPeca.vue'
import ComponentConteudoParceiros from '@/components/ComponentConteudoParceiros.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { title: 'E-Peça', metaTags: [ { name: 'description', content: 'A plataforma de e-commerce especialista em peças' } ] }
    },
    {
      path: '/:pagina',
      name: 'Especifica',
      component: Especifica,
      children: [
        {
          path: '/mapa-do-site',
          name: 'MapaDoSite',
          component: ComponentConteudoMapaDoSite,
          meta: { title: 'Mapa do site', metaTags: [ { name: 'description', content: 'Todos os links do site.' } ] }
        },
        {
          path: '/nossos-produtos',
          name: 'NossosProdutos',
          component: ComponentConteudoNossosProdutos,
          meta: { title: 'Nossos produtos', metaTags: [ { name: 'description', content: 'Conheça os produtos exclusivos da plataforma E-Peça.' } ] }
        },
        {
          path: '/obstaculos-venda-internet',
          name: 'ObstaculosVendaInternet',
          component: ComponentConteudoObstaculosVendaInternet,
          meta: { title: 'Obstáculos da venda de peças', metaTags: [ { name: 'description', content: 'Conheça os obstáculos da venda de peças pela Internet.' } ] }
        },
        {
          path: '/por-que-vender-pecas-online',
          name: 'PorQueVenderPecasOnline',
          component: ComponentConteudoPorQueVenderPecasOnline,
          meta: { title: 'Por que vender peças online?', metaTags: [ { name: 'description', content: 'Aproveite que o e-commerce está em expansão e tenha um novo canal de vendas para chegar ainda mais longe.' } ] }
        },
        {
          path: '/sobre-o-epeca',
          name: 'SobreOEPeca',
          component: ComponentConteudoSobreOEPeca,
          meta: { title: 'Sobre o E-Peça', metaTags: [ { name: 'description', content: 'A plataforma E-Peça resolve a problemática de venda de peças online, de forma prática e lógica, para peças de qualquer tipo de veículo ou máquina.' } ] }
        },
        {
          path: '/*',
          name: 'NaoEncontrada',
          component: ComponentConteudo404,
          meta: { title: '404', metaTags: [ { name: 'description', content: 'Página não encontrada.' } ] }
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

let vm = router.app

router.beforeEach(
    (to, from, next) => {

      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

      const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
      const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

      if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

      if(!nearestWithMeta) return next();

      nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });

        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
      })
          .forEach(tag => document.head.appendChild(tag));

      next()
    }
)

router.beforeResolve(
    (to, from, next) => {
      next()
    }
)

router.afterEach(
    (to, from) => {
      //Registra a mudanca de pagina no GA
      router.app.$ga.page(to.path)
    }
)

export default router
