<template>
  <div id="app">
    <main>
      <ComponentTopo v-if="$route.name !== 'Home'"></ComponentTopo>
      <router-view/>
      <ComponentRodape></ComponentRodape>
    </main>
  </div>
</template>

<script>

  import ComponentRodape from '@/components/ComponentRodape.vue'
  import ComponentTopo from '@/components/ComponentTopo.vue'

  export default {
    name: 'Home',
    props: [],
    mixins: [],
    components: {
      ComponentRodape,
      ComponentTopo
    },//components
    data() {
      return {}
    },//data
    computed: {},//computed
    methods: {},//methods
    watch: {},//watch
    created() {},//created
    mounted() {},//mounted
    destroyed() {},//destroyed
    beforeRouteEnter(to, from, next) {
      next(
        vm => {}
      )
    }
  }
</script>

<style>
</style>