<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col">

                    <h2 class="text-center">Por que vender peças online?</h2>

                    <hr class="curto">

                    <div class="row row-icones">
                        <div class="col-12 col-md-4 mt-4 text-center">
                            <!-- <img alt="" src="img/smartphone.svg"> -->
                            <img alt="" src="img/icones-azuis/EPeca_Ecommerce_Em_Expansao.svg">
                            <h3>E-commerce em expansão</h3>
                            <p class="pl-4 pr-4">Cada vez mais consumidores querendo comprar pela Internet.</p>
                        </div>
                        <div class="col-12 col-md-4 mt-4 text-center">
                            <!-- <img alt="" src="img/money.svg"> -->
                            <img alt="" src="img/icones-azuis/Epeca_Um_Novo_Canal_De_Vendas.svg">
                            <h3>Um novo canal de vendas</h3>
                            <p class="pl-4 pr-4">Seu negócio aberto 24 horas por dia, 7 dias por semana.</p>
                        </div>
                        <div class="col-12 col-md-4 mt-4 text-center">
                            <!-- <img alt="" src="img/placeholder.svg"> -->
                            <img alt="" src="img/icones-azuis/EPeca_Maior_Abrangencia.svg">
                            <h3>Maior abrangência</h3>
                            <p class="pl-4 pr-4">Amplie seu alcance vendendo para clientes de todo o país.</p>
                        </div>
                    </div>

                    <hr class="curto">

                    <h2 class="text-center titulo-estatisticas">Estatísticas do e-commerce no Brasil*</h2>

                    <div class="row row-estatisticas">
                        <div class="col-12 col-md-4 mt-4 text-center">
                            <h3>59,8bi</h3>
                            <p>Previsão de faturamento (em reais) do comércio eletrônico no Brasil em 2019.</p>
                        </div>
                        <div class="col-12 col-md-4 mt-4 text-center">
                            <h3>24%</h3>
                            <p>Crescimento do comércio eletrônico no Brasil entre 2017 e 2019.</p>
                        </div>
                        <div class="col-12 col-md-4 mt-4 text-center">
                            <h3>144mi</h3>
                            <p>Previsão de volume de pedidos online no Brasil em 2019.</p>
                        </div>
                    </div>

                    <div class="row row-texto-dados-do-relatorio">
                        <div class="col text-center">
                            <p>* Dados do relatório Webshoppers edição 40, produzido pela Ebit | Nielsen divulgado em 2019</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoPorQueVenderPecasOnline',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        background-color: #EAF2F5;
        padding: 2em 0;
    }

    section h2
    {
        color: #165FA4;
        font-size: 2.2em;
        font-weight: 300;
    }

    section .titulo-estatisticas
    {
        color: #165FA4;
        font-size: 1.5em;
        margin-top: 2em;
    }

    section .row-icones
    {
        margin-bottom: 2em;
    }

    section .row-icones img
    {
        margin-bottom: 1.5em;
        max-height: 150px;
        max-width: 150px;
    }

    section .row-icones h3
    {
        font-size: 1.2em;
        font-weight: bold;
    }

    section .row-icones .col
    {
        padding: 0 3em;
    }

    section .row-estatisticas
    {
        margin-top: 3em;
    }

    section .row-estatisticas h3
    {
        font-size: 3.5em;
        font-weight: 800;
    }

    section .row-estatisticas > div
    {
        color: #165FA4;
        padding: 0 3em;
    }

    section .row-texto-dados-do-relatorio
    {
        margin-top: 3em;
    }

    section .row-texto-dados-do-relatorio p
    {
        color: #165FA4;
        opacity: 0.5;
    }

    section .container-botao-ver-mais-estatisticas
    {
        margin-top: 1.5em;
    }

    section .container-botao-ver-mais-estatisticas a
    {
        border: 3px solid #165FA4;
        border-radius: 3px;
        color: #165FA4;
        display: inline-block;
        font-size: 1.2em;
        font-weight: bold;
        padding: 0.6em 1.8em;
        text-decoration: none;

        transition: all 0.2s ease;
    }

    section .container-botao-ver-mais-estatisticas a:hover
    {
        background-color: #165FA4;
        color: #fff;
    }

    /* Small devices (landscape phones, 576px and up) */

    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
