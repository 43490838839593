<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col">

                    <h2 class="text-center">Sobre o E-Peça</h2>

                    <div class="text-center container-logo-cor">
                        <img alt="E-Peça" class="logo-epeca" src="/img/logo-epeca-cor.svg">
                    </div>

                    <hr class="curto">

                    <p>A plataforma E-Peça é o resultado da obstinação de dois empreendedores, Raul e Sérgio, na resolução da <strong>problemática de venda online</strong>, de forma prática e lógica, de peças de reposição para <strong>qualquer tipo de veículo ou máquina</strong>.</p>
                    <p>O ano era 2012, e o lugar, uma pequena loja de autopeças em Curitiba. Foi lá que a intenção de vender peças de reposição na internet se mostrou um verdadeiro desafio em vários aspectos. Primeiramente o cadastramento das peças, um processo demorado e complexo, principalmente pela etapa de <strong>vínculo entre peça e aplicação (veículo)</strong>.</p>
                    <p>Depois, a incapacidade das plataformas de e-commerce atuantes no mercado brasileiro em apresentar as informações cadastradas para o consumidor final.</p>
                    <p>Então, o que curiosamente nasceu como uma macro Excel em 2012 com o intuito de somente agilizar o cadastramento de peças para oferta em uma plataforma de e-commerce terceirizada, se transformou em 3 anos de um projeto ousado para criar uma <strong>plataforma completamente dedicada à venda de peças e acessórios</strong> para qualquer tipo de veículo ou máquina.</p>
                    <p>Atualmente a plataforma E-Peça conta com um <strong>cadastrador de peças de excelência</strong>, capaz de acelerar o cadastramento de peças através de várias funcionalidades exclusivas, e um <strong>e-commerce</strong> totalmente adaptado para guiar o consumidor até a peça buscada.</p>
                    <p>Escolhida para ser a fornecedora de plataforma de e-commerce e cadastramento de peças pela rede de concessionárias Harley-Davidson do Brasil, a plataforma E-Peça está lançando uma nova fase no e-commerce brasileiro. Neste projeto a plataforma evoluiu uma vez mais, englobando a <strong>venda de roupas e acessórios de boutique</strong> no mesmo ambiente de e-commerce.</p>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoSobreOEpeca',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        background-color: #EAF2F5;
        color: #165FA4;
        padding: 3em 0;
    }

    section h2
    {
        display: none;
    }

    section img
    {
        height: 5em;
        margin-bottom: 2em;
        max-width: 80%;
    }

    section p
    {
        margin-top: 2.4em;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
