<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 order-last order-md-first">

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.2380680698307!2d-49.26844978541811!3d-25.430303183788265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce46adfe85153%3A0xe6a32c51b7488415!2sR.+Mal.+Deodoro%2C+630+-+Centro%2C+Curitiba+-+PR%2C+81170-290!5e0!3m2!1spt-BR!2sbr!4v1538388807400" width="100%" height="380" frameborder="0" style="border:0" allowfullscreen></iframe>

                    <div class="mt-2 text-center container-texto-endereco">
                        R. Marechal Deodoro, 630  Sl 1607 - Centro - Curitiba/PR
                    </div>

                </div>
<!--<div class="col-12 col-md-5 order-first order-md-last mb-4 mb-md-0">-->

<!--    <h2>Entre em contato</h2>-->

<!--    <div class="container-campos">-->
<!--        <form action="api/contato.api.php" method="post">-->
<!--            <div><input type="text" class="input-nome" name="nome" placeholder="Nome" required></div>-->
<!--            <div><input type="email" class="input-email" name="email" placeholder="E-mail" required></div>-->
<!--            <div><input type="tel" class="input-telefone" name="telefone" placeholder="Telefone" required></div>-->
<!--            <div><textarea class="textarea-mensagem" name="mensagem" placeholder="Mensagem" rows="4" required></textarea></div>-->
<!--            <div class="text-center text-md-left"><button type="submit" class="btn btn-dark botao-enviar">Enviar</button></div>-->
<!--        </form>-->
<!--    </div>-->

<!--</div>-->
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoContato',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        padding: 2em 0;
    }

    section h2
    {
        color: #165FA4;
        font-size: 2.2em;
        font-weight: 300;
        margin-bottom: 0.75em;
    }

    section .container-campos
    {

    }

    section .container-campos > form > div
    {
        margin-bottom: 1em;
    }

    section .container-campos > form > div input,
    section .container-campos > form > div textarea
    {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #165FA4;
        display: block;
        font-size: 1.1em;
        font-weight: 300;
        padding: 0.5em 0;
        width: 100%;
    }

    section .container-campos > form > div ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #165FA4;
    }
    section .container-campos > form > div ::-moz-placeholder { /* Firefox 19+ */
        color: #165FA4;
    }
    section .container-campos > form > div :-ms-input-placeholder { /* IE 10+ */
        color: #165FA4;
    }
    section .container-campos > form > div :-moz-placeholder { /* Firefox 18- */
        color: #165FA4;
    }

    section .container-campos > form > div button
    {
        padding: 0.4em 2em;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

        section .container-campos .botao-enviar
        {
            font-size: 1.1em;
            margin-top: 0.5em;
            padding: 0.5em;
            width: 100%;
        }

    }
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
