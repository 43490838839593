<template>
    <section>
        <router-view></router-view>
    </section>
</template>

<script>
    export default {
        name: 'Especifica',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {

    }

    >>> .section-conteudo
    {
        min-height: 418px;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 575px) {

    }
    @media (min-width: 576px) {

    }
    @media (min-width: 576px) and (max-width: 767px) {

    }
    @media (max-width: 767px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {

    }
    @media (min-width: 768px) and (max-width: 991px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (max-width: 991px) {

    }
    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }
    @media (min-width: 992px) and (max-width: 1199px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
