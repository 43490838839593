<template>
  <section>
    <ComponentConteudoInicial></ComponentConteudoInicial>
    <a name="por-que-vender-pecas-online"></a>
    <ComponentConteudoPorQueVenderPecasOnline></ComponentConteudoPorQueVenderPecasOnline>
    <ComponentQuerConhecerMelhorOsDetalhes></ComponentQuerConhecerMelhorOsDetalhes>
    <a name="obstaculos-venda-internet"></a>
    <ComponentConteudoObstaculosVendaInternet></ComponentConteudoObstaculosVendaInternet>
    <a name="nossos-produtos"></a>
    <ComponentConteudoNossosProdutos></ComponentConteudoNossosProdutos>
    <ComponentQuerConhecerMelhorOsDetalhes></ComponentQuerConhecerMelhorOsDetalhes>
    <a name="parceiros"></a>
    <ComponentConteudoParceiros></ComponentConteudoParceiros>
    <a name="sobre-o-epeca"></a>
    <ComponentConteudoSobreOEPeca></ComponentConteudoSobreOEPeca>
    <a name="contato"></a>
    <ComponentConteudoContato></ComponentConteudoContato>
  </section>
</template>

<script>

  import ComponentConteudoContato from '@/components/ComponentConteudoContato.vue'
  import ComponentConteudoInicial from '@/components/ComponentConteudoInicial.vue'
  import ComponentConteudoNossosProdutos from '@/components/ComponentConteudoNossosProdutos.vue'
  import ComponentConteudoObstaculosVendaInternet from '@/components/ComponentConteudoObstaculosVendaInternet.vue'
  import ComponentConteudoPorQueVenderPecasOnline from '@/components/ComponentConteudoPorQueVenderPecasOnline.vue'
  import ComponentConteudoSobreOEPeca from '@/components/ComponentConteudoSobreOEPeca.vue'
  import ComponentConteudoParceiros from '@/components/ComponentConteudoParceiros.vue'

  import ComponentQuerConhecerMelhorOsDetalhes from '@/components/ComponentQuerConhecerMelhorOsDetalhes.vue'

  export default {
    name: 'Home',
    props: [],
    mixins: [],
    components: {
      ComponentConteudoContato,
      ComponentConteudoInicial,
      ComponentConteudoNossosProdutos,
      ComponentConteudoObstaculosVendaInternet,
      ComponentConteudoParceiros,
      ComponentConteudoPorQueVenderPecasOnline,
      ComponentConteudoSobreOEPeca,
      ComponentQuerConhecerMelhorOsDetalhes
    },//components
    data() {
      return {}
    },//data
    computed: {},//computed
    methods: {},//methods
    watch: {},//watch
    created() {},//created
    mounted() {},//mounted
    destroyed() {},//destroyed
    beforeRouteEnter(to, from, next) {
      next(
        vm => {}
      )
    }
  }
</script>

<style scoped>
  #view-home
  {

  }

  /* Small devices (landscape phones, 576px and up) */
  @media (max-width: 575px) {

  }
  @media (min-width: 576px) {

  }
  @media (min-width: 576px) and (max-width: 767px) {

  }
  @media (max-width: 767px) {

  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }
  @media (min-width: 768px) and (max-width: 991px) {

  }

  /* Large devices (desktops, 992px and up) */
  @media (max-width: 991px) {

  }
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {

  }
  @media (min-width: 992px) and (max-width: 1199px) {

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }
</style>
