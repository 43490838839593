<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
                    <span class="texto-404">Ops!</span>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
                    <span class="texto-pagina-nao-encontrada">Página não encontrada</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudo404',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        display: flex;
        align-items: center;

        padding: 2em 0;
    }

    .texto-404
    {
        font-size: 10em;
        font-weight: 600;
        opacity: 0.15;
    }

    .texto-pagina-nao-encontrada
    {
        font-size: 4em;
        font-weight: 100;
        line-height: 100%;
        opacity: 0.5;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

    }
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
