<template>
    <section>

        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 text-center col-texto-quer-conhecer-melhor">
                    <b>Quer conhecer melhor os detalhes?</b> <button type="button" @click="botaoSolicitePeloChatClick">Solicite</button> uma apresentação pelo chat.
                </div>
<!--<div class="col-12 col-sm-6 col-md-4 mt-4 mt-md-0">-->
<!--    <input type="email" class="form-control" name="email" placeholder="Digite seu e-mail" required>-->
<!--</div>-->
<!--<div class="col-12 col-sm-6 col-md-3 mt-4 mt-md-0">-->
<!--    <button type="submit" class="form-control btn btn-secondary botao-solicitar">Solicitar apresentação</button>-->
<!--</div>-->
            </div>
        </div>

    </section>
</template>

<script>
    export default {
        name: 'ComponentQuerConhecerMelhorOsDetalhes',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {

            botaoSolicitePeloChatClick(e) {

                //Veririca se a API do Jivo já está disponível
                if(window.jivo_api) {

                    window.addEventListener(
                        'jivo_onOpen',
                        () => {

                            // window.jivo_api.setCustomData(
                            //     [
                            //         {
                            //             key: 'Motivo',
                            //             content: 'Quero receber uma apresentação sobre o E-Peça',
                            //             title: 'Motivo'
                            //         }
                            //     ]
                            // )

                        },
                        false
                    )

                    window.jivo_api.open()

                } else {
                    console.error('[Site institucional E-Peça] - A API do Jivo ainda não está disponível')
                }

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        background-color: #C4D8EA;
        padding: 1.5em 0;
    }

    section input
    {
        border: none;
    }

    section button
    {
        background-color: #165FA4;
        border: none;
    }

    section .col-texto-quer-conhecer-melhor
    {
        color: #165FA4;
        font-size: 1.6em;
        font-weight: 300;
    }

    section .col-texto-quer-conhecer-melhor b
    {
        font-weight: 600;
        margin-right: 0.5em;
    }

    section .col-texto-quer-conhecer-melhor button
    {
        background-color: transparent;
        color: inherit;
        cursor: pointer;
        font-size: 1em;
        outline: none;
        padding: 0;
        text-decoration: underline;
    }

    /* Small devices (landscape phones, 576px and up) */

    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

        section .col-texto-quer-conhecer-melhor
        {
            text-align: center;
        }

    }

    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
