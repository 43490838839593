<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col">

                    <h2 class="text-center">Nossos produtos</h2>

                    <hr class="curto">

                    <div class="row row-produtos">
                        <div class="col-12 col-md-6 col-lg-4 text-center">
                            <div>
                                <img alt="" src="img/icones-azuis/EPeca_Cadastrador_De_Pecas_Profissional.svg">
                                <p>
                                    Cadastrador de peças<span>Profissional, com fotos 360&ordm;</span>
                                    <!-- <a class="d-block btn btn-sm btn-light mt-2 mb-4" href="#"><i class="fas fa-plus"></i> Ver mais</a> -->
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 text-center">
                            <div>
                                <img alt="" src="img/icones-azuis/EPeca_Sistema_B2C_Venda_Para_Cliente_Final.svg">
                                <p>Sistema B2C<br><span>Venda para cliente final</span></p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 text-center">
                            <div>
                                <img alt="" src="img/icones-azuis/EPeca_Sistema_B2B_Venda_Entre_Concessionarios_E_Filiais.svg">
                                <p>Sistema B2B<br><span>Venda entre concessionários/filiais</span></p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 text-center">
                            <div>
                                <img alt="" src="img/icones-azuis/EPeca_Sistema_Integrado_B2C_E_B2B.svg">
                                <p>Sistema integrado<br><span>B2C + B2B</span></p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 text-center">
                            <div>
                                <img alt="" src="img/icones-azuis/EPeca_Integracao_Com_ERP_E_Market_Places.svg">
                                <p>Integração com ERPs e Market places</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 text-center">
                            <div>
                                <img alt="" src="img/icones-azuis/EPeca_Aplicativo_Mobile_Para_Venda_E_Consulta_Online.svg">
                                <p>Aplicativo Mobile<br><span>Para venda e consulta online</span></p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoNossosProdutos',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        padding: 2em 0;
    }

    section h2
    {
        color: #165FA4;
        font-size: 2.2em;
        font-weight: 300;
    }

    section img
    {
        margin: 2em 0;
        width: 60%;
    }

    section p
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: #165FA4;
        border-radius: 6px;
        color: #fff;
        font-size: 1.5em;
        font-weight: 300;
        height: 4em;
        line-height: 130%;
        margin-top: 1em;
        padding: 1em 1.5em;
    }

    section p span
    {
        display: block;
        font-size: 0.7em;
    }

    section .row-produtos
    {
        margin-top: 2em;
    }

    section .row-produtos > div  > div
    {
        /* background-color: #f0f0f0; */
        border-radius: 6px;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
