<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col">

                    <h2 class="text-center">Clientes</h2>
                    <hr class="curto">

                    <div class="mb-md-3 row row-assobrav">
                        <div class="col-12 col-md-12 text-center col-logo">
                            <img src="/img/logos-clientes/logo-assobrav.svg" alt="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 text-center col-logo">
                            <img src="/img/logos-clientes/logo-h-d.svg" alt="">
                        </div>
                        <div class="col-12 col-md-4 text-center col-logo">
                            <img src="/img/logos-clientes/logo-ktm.svg" alt="">
                        </div>
                        <div class="col-12 col-md-4 text-center col-logo">
                            <img src="/img/logos-clientes/logo-sdlg.svg" alt="">
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoParceiros',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        padding: 4em 0;
    }

    h2
    {
        color: #165FA4;
        font-size: 2.2em;
        font-weight: 300;
    }

    .container-logos
    {
        display: flex;
        justify-content: space-between;

        margin: 3em 0;
    }

    .col-logo img
    {
        margin: 1em 0;
        height: 80px;
        max-width: 80%;
    }

    .row-assobrav .col-logo img
    {
        height: 50px;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
