<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">

                    <h2>Obstáculos comuns da venda de peças pela Internet</h2>

                    <ul>
                        <li>Difícil categorização e organização das informações</li>
                        <li>Entendimento da aplicação da peça</li>
                        <li>Fotos com baixa qualidade</li>
                        <li>Cliente não tem certeza da compatibilidade da peça</li>
                    </ul>

                </div>
                <div class="col-12 col-lg-6">
                    <img alt="" class="img-fluid mt-5" src="/img/bg-imagem-laptop-site-obstaculos.png">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoObstaculosVendaInternet',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        padding: 3em 0;
    }

    section h2
    {
        color: #165FA4;
        font-size: 2.2em;
        font-weight: 300;
    }

    section ul
    {
        font-size: 1.1em;
        line-height: 320%;
        margin-top: 2em;
        padding: 0;
    }

    section ul li
    {
        list-style: none;
    }

    section ul li:before
    {
        content: '';

        background-image: url(/img/verified.svg);
        display: inline-block;
        height: 1.4em;
        margin-right: 1em;
        vertical-align: middle;
        width: 1.4em;
    }

    section .bg-imagem-laptop-site-obstaculos
    {
        background-image: url(/img/bg-imagem-laptop-site-obstaculos.png);
        background-position: 50% top;
        background-repeat: no-repeat;
        background-size: contain;
        height: 380px;
        margin-top: 3.5em;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

        section ul li
        {
            line-height: 140%;
            margin-bottom: 1em;
        }

        section ul li:before
        {
            height: 1.2em;
            margin-right: 0.5em;
            width: 1.2em;
        }
    }
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
