<template>
    <header>
        <div class="row-menu container-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-9 col-md-3">
                        <router-link to="/"><img alt="E-Peça" class="logo-epeca" src="img/logo-epeca-branca.svg"></router-link>
                    </div>
                    <div class="col-3 col-md-9 text-right">
                        <nav class="d-none d-lg-block">
                            <router-link to="/por-que-vender-pecas-online">Vender online</router-link>
                            <router-link to="/obstaculos-venda-internet">Obstáculos</router-link>
                            <router-link to="/nossos-produtos">Nossos produtos</router-link>
                            <router-link to="/sobre-o-epeca">Sobre o E-Peça</router-link>
<!--<router-link to="/contato">Contato</router-link>-->
                        </nav>
                        <button class="btn btn-light d-lg-none botao-menu" @click="botaoMenuMobileClick"><i class="fas fa-bars"></i></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-menu-mobile" @click="rowMenuMobileClick" v-if="menuMobile.show">
            <div class="col">
                <nav class="text-center">
                    <router-link to="/por-que-vender-pecas-online">Vender online</router-link>
                    <router-link to="/obstaculos-venda-internet">Obstáculos</router-link>
                    <router-link to="/nossos-produtos">Nossos produtos</router-link>
                    <router-link to="/sobre-o-epeca">Sobre o E-Peça</router-link>
<!--<router-link to="/contato">Contato</router-link>-->
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'ComponentTopo',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {
                menuMobile: {
                    show: false
                }
            }
        },//data
        computed: {},//computed
        methods: {

            fecharMenu() {
                this.menuMobile.show = false
            },

            rowMenuMobileClick(e) {
                this.fecharMenu()
            },

            botaoMenuMobileClick(e) {
                this.menuMobile.show = !this.menuMobile.show
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    .row-menu
    {
        background-color: #004990;
        padding: 1.4em 0;
    }

    .row-menu .logo-epeca
    {
        height: 2.5em;
    }

    .row-menu nav a
    {
        color: #fff;
        font-size: 1.1em;
        margin-left: 1em;
    }

    .row-menu nav a:hover
    {
        color: #FFCE00;
        text-decoration: none;
    }

    .row-menu-mobile
    {
        background-color: #fff;
        border-radius: 6px;
        margin: 1em;
        padding: 1em;
    }

    .row-menu-mobile a
    {
        color: #165FA4;
        display: block;
        font-size: 1.2em;
        padding: 0.75em;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

    }
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 768px) and (max-width: 991px) {

    }
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
