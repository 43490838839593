<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 order-last order-md-first col-esquerda">
                    <img alt="E-Peça" class="logo-epeca" src="/img/logo-epeca-branca.svg">
                    <div class="container-slogan">A plataforma de e-commerce especialista em peças</div>
                    <div class="container-botoes-midias-sociais">
                        <a href="https://www.facebook.com/E-pe%C3%A7a-123571249036658/?view_public_for=123571249036658" target="_blank"><i class="fab fa-facebook"></i></a>
                        <a href="https://instagram.com/plataformaepeca?igshid=jx25k947ukwg" target="_blank"><i class="fab fa-instagram"></i></a>
<!--<a href="#" target="_blank"><i class="fab fa-linkedin"></i></a>-->
                    </div>
                </div>
                <nav class="col-12 col-md-3 col-lg-4 col-menu">
                    <ul>
                        <li><router-link to="/por-que-vender-pecas-online">Vender online</router-link></li>
                        <li><router-link to="/obstaculos-venda-internet">Obstáculos</router-link></li>
                        <li><router-link to="/nossos-produtos">Nossos produtos</router-link></li>
                        <li><router-link to="/sobre-o-epeca">Sobre o E-Peça</router-link></li>
                        <li><router-link to="/mapa-do-site">Mapa do site</router-link></li>
                    </ul>
                </nav>
                <div class="col-12 col-md-5 col-lg-4 order-first order-md-last col-telefones-endereco">
                    <div>
                        <img alt="" src="/img/call-answer.svg">
                        <span>+55 41 3015-0028</span>
                    </div>
                    <div>
                        <img alt="" src="/img/envelope.svg">
                        <a href="mailto:atendimento@e-peca.com.br">atendimento@e-peca.com.br</a>
                    </div>
                    <div>
                        <img alt="" src="/img/maps-and-flags.svg">
                        <span>R. Marechal Deodoro, 630<br>Sl 1607 - Centro<br>Curitiba/PR</span>
                    </div>
                </div>
            </div>
            <div class="row row-copyright">
                <div class="col p-4 text-center">
                    &copy; Todos os direitos reservados
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'ComponentRodape',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    footer
    {
        background-color: #004990;
        padding: 2em 0;
    }

    footer ul
    {
        color: #fff;
        font-size: 1.1em;
        font-weight: 100;
        line-height: 200%;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    footer ul a
    {
        color: #fff;
        text-decoration: none;
    }

    footer ul a:hover
    {
        color: #FFCE00;
        text-decoration: none;
    }

    footer .col-telefones-endereco a:hover
    {
        color: #FFCE00;
    }

    footer .col-esquerda .container-slogan
    {
        color: #fff;
        margin-bottom: 1em;
        margin-top: 1em;
        width: 14em;
    }

    footer .col-esquerda .container-botoes-midias-sociais
    {

    }

    footer .col-esquerda .container-botoes-midias-sociais a
    {
        color: #fff;
        font-size: 1.65em;
        margin-right: 0.5em;
    }

    footer .col-esquerda .container-botoes-midias-sociais a:hover
    {
        color: #FFCE00;
    }

    footer .logo-epeca
    {
        height: 2em;
    }

    footer .col-telefones-endereco
    {
        color: #fff;
    }

    footer .col-telefones-endereco img
    {
        height: 1em;
        margin-top: 0.15em;
        vertical-align: top;
    }

    footer .col-telefones-endereco > div
    {
        margin-bottom: 1.5em;
    }

    footer .col-telefones-endereco > div:last-child
    {
        margin-bottom: 0;
    }

    footer .col-telefones-endereco > div span,
    footer .col-telefones-endereco > div a
    {
        display: inline-block;
        margin-left: 0.5em;
    }

    footer .col-telefones-endereco a
    {
        color: #fff;
        text-decoration: underline;
    }

    footer .col-telefones-endereco a:hover
    {
        color: #FFCE00;
    }

    footer .row-copyright
    {
        border-top: 1px solid #fff;
        color: #fff;
        font-size: 0.8em;
        font-weight: 300;
        margin-top: 2em;
        opacity: 0.35;
    }

    footer .row-copyright .col
    {
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        text-align: center;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

        footer ul
        {
            font-weight: 300;
            line-height: 240%;
        }

        footer .col-menu
        {
            margin: 2.5em 0;
        }

    }
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 768px) and (max-width: 991px) {

        footer .col-esquerda
        {
            padding-right: 2em;
        }

    }
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

        footer .col-esquerda p
        {
            width: 250px;
        }

    }

</style>
