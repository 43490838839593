import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//vue-analytics
import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-148827274-1'
})

//vue-plugin-load-script
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

Vue.config.productionTip = false

Vue.prototype.$eventbus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
