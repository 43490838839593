<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col">

                    <h2>Mapa do site</h2>

                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <br>
                        <li><a href="/nossos-produtos">Nossos produtos</a></li>
                        <br>
                        <li><a href="/obstaculos-venda-internet">Obstáculos comuns da venda de peças pela Internet</a></li>
                        <br>
                        <li><a href="/por-que-vender-pecas-online">Por que vender peças online?</a></li>
                        <br>
                        <li><a href="/sobre-o-epeca">Sobre o E-Peça</a></li>
                    </ul>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoMapaDoSite',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        padding: 1.4em 0;
    }

    section h2
    {
        color: #165FA4;
        font-size: 2.2em;
        font-weight: 300;
        margin-bottom: 1em;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

    }
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
