<template>
    <section class="section-conteudo">
        <div class="container">
            <div class="row">
                <div class="col">

                    <header class="row">
                        <div class="col-9 col-md-3">
                            <img alt="E-Peça" class="logo-epeca" src="img/logo-epeca-branca.svg">
                        </div>
                        <div class="col-3 col-md-9 text-right">
                            <nav class="d-none d-lg-block">
                                <a href="#por-que-vender-pecas-online">Vender online</a>
                                <a href="#obstaculos-venda-internet">Obstáculos</a>
                                <a href="#nossos-produtos">Nossos produtos</a>
                                <a href="#sobre-o-epeca">Sobre o E-Peça</a>
<!--<a href="#contato">Contato</a>-->
                            </nav>
                            <button class="btn btn-light d-lg-none botao-menu" @click="botaoMenuMobileClick"><i class="fas fa-bars"></i></button>
                        </div>
                    </header>

                    <div class="row row-menu-mobile" @click="rowMenuMobileClick" v-if="menuMobile.show">
                        <div class="col">
                            <nav class="text-center">
                                <a href="#por-que-vender-pecas-online">Vender online</a>
                                <a href="#obstaculos-venda-internet">Obstáculos</a>
                                <a href="#nossos-produtos">Nossos produtos</a>
                                <a href="#sobre-o-epeca">Sobre o E-Peça</a>
<!--<a href="#contato">Contato</a>-->
                            </nav>
                        </div>
                    </div>

                    <h1 class="text-center">Vender <strong>peças online</strong> agora é fácil!</h1>
                    <p class="text-center">Com o E-Peça você vende peças de carros, motos, kart, barcos, caminhões e qualquer outro tipo de peças em sua <strong>própria loja virtual</strong>.</p>

                    <div class="text-center container-botao-quero-saber">
                        <a href="#por-que-vender-pecas-online">Quero saber por que</a>
                    </div>

                    <div class="text-center bg-imagem-navegador-site-inicial"></div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ComponentConteudoInicial',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {
                menuMobile: {
                    show: false
                }
            }
        },//data
        computed: {},//computed
        methods: {

            fecharMenu() {
                this.menuMobile.show = false
            },

            rowMenuMobileClick(e) {
                this.fecharMenu()
            },

            botaoMenuMobileClick(e) {
                this.menuMobile.show = !this.menuMobile.show
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        }
    }
</script>

<style scoped>
    section
    {
        background-color: #0E408F;
        background-image: url(/img/bg-inicial.svg);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 2;
    }

    section header
    {
        margin: 2.5em 0;
    }

    section header nav a
    {
        color: #fff;
        font-size: 1.1em;
        margin-left: 1em;
    }

    section h1
    {
        color: #fff;
        font-size: 3em;
        font-weight: bold;
        margin-top: 1.5em;
        padding: 0 1em;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.35);
    }

    section h1 strong
    {
        color: #FFCE00;
        font-weight: bold;
    }

    section p
    {
        color: #fff;
        font-size: 1.4em;
        font-weight: 300;
        margin-bottom: 2em;
        padding: 0 4em;
    }

    section p strong
    {
        color: #FFCE00;
        font-weight: normal;
    }

    section .logo-epeca
    {
        height: 2.5em;
    }

    section .container-botao-quero-saber
    {
        margin-bottom: 1.5em;
    }

    section .container-botao-quero-saber a
    {
        border: 3px solid #FFCE00;
        border-radius: 3px;
        color: #FFCE00;
        display: inline-block;
        font-size: 1.2em;
        font-weight: bold;
        padding: 0.6em 1.8em;
        text-decoration: none;

        transition: all 0.2s ease;
    }

    section .container-botao-quero-saber a:hover
    {
        background-color: #FFCE00;
        color: #0E408F;
    }

    section .bg-imagem-navegador-site-inicial
    {
        background-image: url(/img/bg-imagem-navegador-site-inicial.png);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: 470px;
        margin: 0 auto;
        max-width: 1050px;
    }

    section .row-menu-mobile
    {
        background-color: #fff;
        border-radius: 6px;
        margin: 1em;
        padding: 1em;
    }

    section .row-menu-mobile a
    {
        color: #165FA4;
        display: block;
        font-size: 1.2em;
        padding: 0.75em;
    }

    /* Small devices (landscape phones, 576px and up) */
    @media (max-width: 575px) {

        section .bg-imagem-navegador-site-inicial
        {
            height: 90px;
        }

    }

    @media (min-width: 576px) {

    }

    /* Medium devices (tablets, 768px and up) */
    @media (max-width: 767px) {

        section
        {
            background-size: 200%;
        }

        section header
        {

        }

        section h1
        {
            font-size: 2.3em;
            letter-spacing: -0.025em;
            margin-top: 1em;
            padding: 0;
        }

        section p
        {
            font-size: 1.1em;
            margin-bottom: 2em;
            margin-top: 1em;
            padding: 0 1em;
        }

        section .container-botao-quero-saber
        {
            margin-bottom: 2.5em;
        }

        section .bg-imagem-navegador-site-inicial
        {
            height: 120px;
        }

    }
    @media (min-width: 768px) {

    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 768px) and (max-width: 991px) {

        section .bg-imagem-navegador-site-inicial
        {
            height: 240px;
        }

    }
    @media (max-width: 991px) {

    }
    @media (min-width: 992px) {

    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {

    }
</style>
